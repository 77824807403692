
import ProductListFilterModule from '@/store/modules/ProductListFilterModule';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
    name: 'ProductTable',
})
export default class ProductTable extends Vue {
    private hasScroll = false;
    
    get expanded() {
        return ProductListFilterModule.EXPANDED_TABLE;
    }

    get tableId() {
        return (this as any)._uid;
    }

    get currentTableId() {
        return ProductListFilterModule.CURRENT_TABLE_ID;
    }
    
    private mounted() {
        
        ProductListFilterModule.updateCurrentTableId(this.tableId);
        
        ProductListFilterModule.getExpandedTable();
        if (this.expanded) {
            this.resize();
        }
        window.addEventListener('resize', this.resize);
        const scrollContainer = this.$el.querySelector('.product-table');
        if (!scrollContainer)
            return;

        this.hasScroll = scrollContainer.scrollWidth > scrollContainer.clientWidth;

        const scrollWatchElement = document.querySelector('.product-table-body-specifications');
        const scrollApplyElement = document.querySelector('.product-table-header-specifications');
        if (scrollWatchElement && scrollApplyElement) {
            scrollWatchElement.addEventListener('scroll', () => {
                scrollApplyElement.scrollLeft = scrollWatchElement.scrollLeft;
            });
        }
    }

    private beforeDestroy() {
        window.removeEventListener('resize', this.resize);
    }

    private resize() {
        const tableContainer = document.querySelector('.product-table') as HTMLElement;
        let offsetAsPixels = "0";
        if (this.expanded) {
            const offset = tableContainer.offsetLeft / 1.5;
            offsetAsPixels = `${offset-2*offset}px`;
        }
        tableContainer.style.marginLeft = offsetAsPixels;
        tableContainer.style.marginRight = offsetAsPixels;
    }

    @Watch('expanded')
    private onExpandedChange() {
        this.resize();
    }

    @Watch('currentTableId')
    private onTableIdChange() {
        if (this.currentTableId !== this.tableId) {
            this.$destroy();
        }
    }

}
