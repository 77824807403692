
import { Component, Vue, Prop } from 'vue-property-decorator';
import { StockMessage } from '../models/Product';

@Component({
    name: 'AddToCartStock'
})
export default class AddToCartStock extends Vue {
    @Prop({ type: Object, required: false, default: null }) private stockMessage: StockMessage;
    @Prop({ type: Boolean, required: false, default: false }) private inline: boolean;
    @Prop({ type: String, required: false, default: 'top' }) private position: boolean;

    get showStock(): boolean {
        return this.stockMessage != null;
    }

    get icon(): string {
        return this.stockMessage?.icon;
    }

    get color(): string {
        return this.stockMessage?.color;
    }

    get message(): string {
        if (!this.stockMessage?.showdeliverymessageonlists) return null;
        
        return this.stockMessage.message;
    }

    get deliveryMessage(): string {
        if (!this.stockMessage?.showdeliverymessageonlists) return null;
        if (this.stockMessage?.color === "uk-text-warning") {
            // @ts-ignore
            return this.$t('stock.low-stock', ['Low stock']);
        } else {
            return "";
        }
    }

    get tooltip(): string {
        return this.stockMessage?.color === "uk-text-warning"
        ?
        (this.$t('stock.low-stock-info', ['Contact customer support for more information']) as string)
        :
        "";
    }
}
