import axios, { AxiosResponse } from 'axios';
import {
    DisplayPriceInfo,
    ProductSearchResult,
    ProductSearchResultProduct,
    SimpleProductQueryRequestModel,
} from '@/models/Product';
import { FilterModel } from '@/models/Filter';
import { MagicSearchRequestModel } from '@/models/MagicSearch';

export default class ProductService {
    private profileId: string = (window as any).CbxApiContextKey;
    private endpoint: string;

    constructor(args: any = {}) {
        if (args.profileId) this.profileId = args.profileId;
        this.endpoint = `/contextapi/${this.profileId}/v1/product`;
    }

    public async getProductsById(ids: string[], imagesize: string = null): Promise<ProductSearchResultProduct[]> {
        try {

            const res: AxiosResponse<ProductSearchResult> = await axios.post(`${this.endpoint}/ids` + (imagesize ? `?imagesize=${imagesize}` : ''), ids);
            return res.data.products;
        } catch (e) {
            console.log(e);
        }
    }

    public async getProductsByQuery(payload: SimpleProductQueryRequestModel): Promise<ProductSearchResultProduct[]> {
        try {
            const res: AxiosResponse<ProductSearchResult> = await axios.post(
                `${this.endpoint}/simpleproductquery`,
                payload
            );
            return res.data.products;
        } catch (e) {
            console.log(e);
        }
    }

    public async search(query: string = '', limit: number = 10): Promise<ProductSearchResultProduct[]> {
        if (query.length === 0) return [];
        try {
            const res: AxiosResponse<ProductSearchResult> = await axios.get(
                `${this.endpoint}/search?query=${query}&limit=${limit}`
            );
            return res.data.products;
        } catch (e) {
            console.log(e);
            return [];
        }
    }

    public async facetSearch(payload: FilterModel): Promise<FilterModel> {
        try {
            const res: AxiosResponse<FilterModel> = await axios.post(`${this.endpoint}/facetsearch`, payload);
            return res.data;
        } catch (e) {
            return {};
        }
    }

    public async magicSearch(params: MagicSearchRequestModel | string): Promise<any> {
        if (typeof params === 'string') {
            params = {
                limit: 12,
                query: params,
            };
        }
        try {
            // const res: AxiosResponse<FilterModel> = await axios.get(`${this.endpoint}/magicSearch?query=${query}&doctype&limit=${limit}&offset=${offset}`);
            const res: AxiosResponse<FilterModel> = await axios({
                method: 'GET',
                url: `${this.endpoint}/magicSearch`,
                params,
            });
            return res.data;
        } catch (e) {
            console.log(e);
            return {};
        }
    }

    public async suggestions(query: string): Promise<string[]> {
        try {
            const res: AxiosResponse<string[]> = await axios({
                method: 'GET',
                url: `${this.endpoint}/suggestions`,
                params: {
                    query,
                },
            });
            return res.data;
        } catch (e) {
            console.log(e);
            return [];
        }
    }

    public async GetTotalPrice(args: any): Promise<DisplayPriceInfo> {
        try {
            const res: AxiosResponse<DisplayPriceInfo> = await axios.post(`${this.endpoint}/calculatetotal`, args);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    /*
    usage:
    const popular = await this.ps.getProductsByList({
        list: 'popularByCategory',
        parameters: {
            '###categoryId###': 'default_all-kitchen-equipment'
        }
    });
    const popular = await this.ps.getProductsByList({
        list: 'popularByBrand',
        parameters: {
            '###brand###': 'Condi'
        }
    });
    */
}
