import Axios from 'axios';

export default class SKFService {
    private profileId: string = (window as any).CbxApiContextKey;
    public endpoint: string;

    constructor(args: any = {}) {
        if (args.profileId)
            this.profileId = args.profileId;
        this.endpoint = `/contextapi/${this.profileId}/v1/skf`;
    }

    public async ensureCartCookie(): Promise<boolean> {
        try {
            await Axios({
                method: 'POST',
                url: `${this.endpoint}/ensurecartcookie`,
            });
            return true;
        } catch (error) {
            return false;
        }
    }
}