import { CustomIndexConfig, FilterModel, IndexInfoModel } from '@/models/Filter';
import axios, { AxiosResponse } from 'axios';

export default class ProductListFilterService {
    private profileId: string = (window as any).CbxApiContextKey;
    private endpoint: string;
    private workerEndpoint: string;
    private productSearchEndpoint: string;

    constructor(args: any = {}) {
        if (args.profileId) this.profileId = args.profileId;
        this.endpoint = `/contextapi/${this.profileId}/v1/facets`;
        this.workerEndpoint = `/api/workers`;
        this.productSearchEndpoint = `/contextapi/${this.profileId}/v1/product/productsearch`;
    }

    public async getFilterResult(payload: FilterModel): Promise<FilterModel> {
        // remove unnecessary properties from the payload before sending
        const p: FilterModel = {
            categories: payload.categories,
            rootcategory: payload.rootcategory,
            count: payload.count,
            offset: payload.offset,
            searchphrase: payload.searchphrase,
            selections: payload.selections,
            sortoptions: payload.sortoptions,
            restrictdoctypes: payload.restrictdoctypes,
            includedescendantcategories: payload.includedescendantcategories,
            fields: payload.fields,
            usefuzzymatch: false,
        };

        if (payload.usecontentrelationsasselection) {
            p.usecontentrelationsasselection = true;
            p.contentrelationnodeid = payload.contentrelationnodeid;
        }

        try {
            const res: AxiosResponse<FilterModel> = await axios.post(`${this.productSearchEndpoint}`, p);
            return res.data;
        } catch (e) {
            return {};
        }
    }

    public async getIndexInfo(): Promise<IndexInfoModel> {
        try {
            const res: AxiosResponse<IndexInfoModel> = await axios.get(`${this.endpoint}/getindexinfo`);
            return res.data;
        } catch (e) {
            return {};
        }
    }

    public async rebuildIndex(): Promise<boolean> {
        try {
            await axios.get(`${this.workerEndpoint}/startjob?job=consolidate-index`);
            return true;
        } catch (e) {
            return false;
        }
    }

    public async updateCustomConfig(payload: CustomIndexConfig): Promise<boolean> {
        try {
            await axios.post(`${this.endpoint}/updatecustomconfig`, payload);
            return true;
        } catch (e) {
            return false;
        }
    }

    public async nukeIndex(): Promise<boolean> {
        try {
            await axios.get(`${this.workerEndpoint}/startjob?job=nuke-index`);
            return true;
        } catch (e) {
            return false;
        }
    }
}
