export function toCssClassName(prefix: string, input: string) {
    return prefix + '-' + input.replace(/([^a-z0-9])/gi, '-').toLowerCase();
}

export function uuid() {
    const url = URL.createObjectURL(new Blob());
    const id = url.split('/').pop();
    URL.revokeObjectURL(url);
    return id;
}

export function daysForLocale(localeName = 'sv-SE') {
    const {format} = new Intl.DateTimeFormat(localeName, { weekday: "long" });
    return [...Array(7).keys()]
        .map((day) => format(new Date(Date.UTC(2021, 5, day))));
}
