
import { VariantDisplayOptions } from '@/models/Variant';
import { CbxVariablesModule } from '@/store/modules/CbxVariablesModule';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ProductSearchResultProduct, StockMessage } from '../../models/Product';
import ProductDetailsModule from '../../store/modules/ProductDetailsModule';
import AddToCartButton from '../AddToCartButton.vue';
import DetailsAddToWishList from './DetailsAddToWishList.vue';
import SKFButton from '../SKFButton.vue';

@Component({
    name: 'DetailsAddToCart',
    components: {
        AddToCartButton,
        SKFButton,
        DetailsAddToWishList
    }
})
export default class DetailsAddToCart extends Vue {

    @Prop({ type: Number, required: false, default: null }) private quantity: number;
    @Prop({ type: Boolean, required: false, default: false }) private enableBulkOrdering: boolean;

    get displayWishListButton(): boolean {
        return CbxVariablesModule.VARIABLES.enableWishList;
    }

    get product(): ProductSearchResultProduct {
        return ProductDetailsModule.PRODUCT;
    }
    
    get sku(): string {
        if (ProductDetailsModule.VARIANTS?.length > 0) {
            return ProductDetailsModule.SELECTED_VARIANT?.sku;
        }
        return this.product?.id;
    }

    get allowpurchase(): boolean {
        if (ProductDetailsModule.VARIANTS?.length > 0) {
            return ProductDetailsModule.SELECTED_VARIANT?.allowpurchase;
        }
        return this.product?.allowpurchase;        
    }

    get isSKF(): boolean {
        return this.product?.isskf;
    }

    get unit(): string {
        if (ProductDetailsModule.VARIANTS?.length > 0) {
            return ProductDetailsModule.SELECTED_VARIANT?.unit;
        }
        return this.product?.unit;
    }

    get colli(): number {
        if (ProductDetailsModule.VARIANTS?.length > 0) {
            return ProductDetailsModule.SELECTED_VARIANT?.colli;
        }
        return this.product?.colli;
    }    

    get qty(): number {
        if (this.quantity != null)
            return this.quantity;
            
        return this.colli || 1;
    }

    get dimension1(): string {
        return ProductDetailsModule.SELECTED_VARIANT?.dimension1code ?? '';
    }
    get dimension2(): string {
        return ProductDetailsModule.SELECTED_VARIANT?.dimensioncount === 2
            ? ProductDetailsModule.SELECTED_VARIANT?.dimension2code
            : '';
    }

    get displayOptions(): VariantDisplayOptions {
        return CbxVariablesModule.VARIABLES.variantDisplayOptions;
    }

    get addonProducts(): string[] {
        if (!CbxVariablesModule.VARIABLES.enableMultipleAddonProductSelection)
            return [];

        return ProductDetailsModule.SELECTED_ADDON_PRODUCTS;
    }

    get stockMessage(): StockMessage {
        if (ProductDetailsModule.VARIANTS?.length > 0) {
            return ProductDetailsModule.SELECTED_VARIANT?.stockmessage;
        }
        return this.product?.stockmessage;      
    }
}
