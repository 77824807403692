import { Module, VuexModule, Mutation, getModule, Action } from 'vuex-module-decorators';
import store from '@/store';
import ProductListFilterService from '@/services/ProductListFilterService';
import MobileNavigationService from '@/services/MobileNavigationService';
import { FilterModel, FilterModelField, FilterRequestSelection, ProductListOptions, SortOption } from '@/models/Filter';
import { CbxVariablesModule } from './CbxVariablesModule';
import ProductService from '@/services/ProductService';

@Module({
    store,
    dynamic: true,
    name: 'productListFilter',
    namespaced: true,
})
class ProductListFilterModule extends VuexModule {
    private svc: ProductListFilterService = new ProductListFilterService();
    private mns: MobileNavigationService = new MobileNavigationService();
    private productService: ProductService = new ProductService();
    // @ts-ignore
    private filterModel: FilterModel = {
        rootcategory: null,
        categories: CbxVariablesModule.VARIABLES.currentCategoryId.length
            ? [CbxVariablesModule.VARIABLES.currentCategoryId]
            : [],
        fields: [],
        hits: [],
        selections: [],
        searchphrase: '',
        count: 20,
        offset: 0,
        hitcount: 0,
        sortoptions: [],
        restrictdoctypes: ['PRODUCT'],
        includedescendantcategories: false,
        usecontentrelationsasselection: false,
        contentrelationnodeid: '',
    };
    private displayMobile: boolean = false;
    private expandedField: FilterModelField = null;
    private productListOptions: ProductListOptions = {
        sortoptions: [],
        selectedsortoption: '',
        pagelimitoptions: [],
        selectedpagelimitoption: 0,
    };
    private expandedTable = false;
    private currentTableId = 0;

    private isLoading: boolean = false;

    get IS_LOADING(): boolean {
        return this.isLoading;
    }
    get FILTER_MODEL(): FilterModel {
        return this.filterModel;
    }

    get DISPLAY_MOBILE(): boolean {
        return this.displayMobile;
    }

    get EXPANDED_FIELD(): FilterModelField {
        return this.expandedField;
    }

    get PRODUCT_LIST_OPTIONS(): ProductListOptions {
        return CbxVariablesModule.VARIABLES.productListOptions;
    }

    get EXPANDED_TABLE() {
        return this.expandedTable;
    }

    get CURRENT_TABLE_ID() {
        return this.currentTableId;
    }

    @Action({ commit: 'SET_FILTER_RESULT' })
    public async GET_FILTER_RESULT() {
        this.setLoading(true);
        return await this.svc.getFilterResult({ ...this.filterModel });
    }

    @Mutation
    public SET_FILTER_RESULT(payload: FilterModel) {
        this.filterModel.fields = payload.fields;
        this.filterModel.totalcount = payload.totalcount;
        this.filterModel.hitcount = payload.hitcount;
        this.filterModel.hits = payload.hits;
        this.filterModel.translations = payload.translations;
        this.isLoading = false;
    }

    @Mutation
    public SET_FILTER_SELECTION(payload: FilterRequestSelection[]) {
        this.filterModel.selections = payload;
    }

    @Mutation
    public SET_FILTER_FIELDS(payload: FilterModelField[]) {
        this.filterModel.fields = payload;
    }

    @Mutation
    public SET_FILTER_CATEGORIES(payload: string[]) {
        this.filterModel.categories = payload;
    }

    @Mutation
    public SET_FILTER_ROOT_CATEGORY(payload: string) {
        this.filterModel.rootcategory = payload;
    }

    @Mutation
    public SET_FILTER_INCLUDE_DESCENDANT_CATEGORIES(payload: boolean) {
        this.filterModel.includedescendantcategories = payload;
    }

    @Mutation
    public SET_FILTER_USE_CONTENT_RELATIONS(nodeid: string) {
        this.filterModel.usecontentrelationsasselection = true;
        this.filterModel.contentrelationnodeid = nodeid;
    }

    @Mutation
    public SET_FILTER_SEARCH_PHRASE(payload: string) {
        this.filterModel.searchphrase = payload;
    }

    @Mutation
    public SET_FILTER_OFFSET(payload: number) {
        this.filterModel.offset = payload;
    }
    @Mutation
    public SET_FILTER_COUNT(payload: number) {
        this.filterModel.count = payload;
    }
    @Mutation
    public SET_FILTER_SORT_OPTIONS(payload: SortOption[]) {
        this.filterModel.sortoptions = payload;
    }

    @Mutation
    public REMOVE_SELECTION_VALUE(payload: any) {
        const { fieldPath, fieldValue } = payload;
        const existingFieldSelection = this.filterModel.selections.find(field => field.path === fieldPath);
        const values = existingFieldSelection.values.filter(val => val !== fieldValue);
        if (values.length === 0) {
            this.filterModel = {
                ...this.filterModel,
                selections: this.filterModel.selections.filter(field => field.path !== fieldPath),
            };
        } else {
            this.filterModel = {
                ...this.filterModel,
                selections: this.filterModel.selections.map(field => {
                    if (field.path === fieldPath) {
                        field.values = values;
                    }
                    return field;
                }),
            };
        }
    }

    @Mutation
    public REMOVE_SELECTION_GROUP(payload: any) {
        this.filterModel.selections = this.filterModel.selections.filter(field => field.path !== payload);
    }

    @Action({ commit: 'SET_MOBILE_FILTER_DISPLAY' })
    public TOGGLE_MOBILE_FILTER_DISPLAY() {
        this.mns.handleBodyOverflow(this.displayMobile);
        return this.displayMobile;
    }

    @Mutation
    private SET_MOBILE_FILTER_DISPLAY(payload: boolean) {
        this.displayMobile = !payload;
    }

    @Action({ commit: 'SET_CLEAR_FILTERS' })
    public async CLEAR_FILTERS(includeSearchPhrase: boolean = true) {
        return includeSearchPhrase;
    }

    @Mutation
    private SET_CLEAR_FILTERS(includeSearchPhrase: boolean = true) {
        this.filterModel.selections = [];
        if (includeSearchPhrase)
            this.filterModel.searchphrase = '';
    }

    @Action({ commit: 'SET_EXPANDED_FIELD' })
    public async EXPAND_FIELD(payload: FilterModelField) {
        return payload;
    }

    @Mutation
    private SET_EXPANDED_FIELD(payload: FilterModelField) {
        this.expandedField = payload;
    }

    @Action({ commit: 'SET_LOADING' })
    private async setLoading(payload: boolean) {
        return payload;
    }
    @Mutation
    private SET_LOADING(payload: boolean) {
        this.isLoading = payload;
    }

    @Action({ commit: 'SET_EXPANDED_TABLE'})
    public async getExpandedTable() {
        const cookieArray = document.cookie.split(';');
        for (const cookie of cookieArray) {
            const [cookieName, cookieValue] = cookie.split('=').map(part => part.trim());
            if (cookieName === 'displayExpandedList') {
                return cookieValue === "true" ? true : false;
            }
        }
        return false;
    }

    @Action({ commit: 'SET_EXPANDED_TABLE'})
    public async updateExpandedTable(payload: boolean) {
        const date = new Date();
        date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
        const expires = "expires=" + date.toUTCString();
        document.cookie = 'displayExpandedList' + "=" + payload.toString() + ";" + expires + ";path=/";
        return payload;
    }
    
    @Mutation
    private SET_EXPANDED_TABLE(payload: boolean) {
        this.expandedTable = payload;
    }

    @Action({ commit: 'SET_CURRENT_TABLE_ID'})
    public async updateCurrentTableId(payload: number) {
        return payload;
    }
    
    @Mutation
    private SET_CURRENT_TABLE_ID(payload: any) {
        this.currentTableId = payload;
    }
}

export default getModule(ProductListFilterModule);
