
import { Component, Vue, Prop } from 'vue-property-decorator';
import LoadingModule from '../store/modules/LoadingModule';
import SKFService from '@/services/SKFService';
import CartModule from '@/store/modules/CartModule';

const service: SKFService = new SKFService();

@Component({
    name: 'SKFButton',
    components: {
        
    }
})
export default class SKFButton extends Vue {
    @Prop({ type: String, required: true }) private product: string;
    @Prop({ type: String, required: false, default: 'default' }) private size: string;
    @Prop({ type: String, required: false, default: '' }) view: string;
    @Prop({ type: String, required: false, default: null }) private buttonText: string;

    get isLoading(): boolean {
        return LoadingModule.IS_LOADING;
    }

    get translationsReady(): boolean {
        return this.buttonText != null || this.$root.$data.translationsLoaded === true;
    }

    get fallbackButtonText(): any {
        return this.buttonText ?? (this.view == 'table' ? this.$t('product.configure', ['Configure']) : this.$t('product.configure-with-skf', ['Configure with SKF']));
    }

    get sizeClasses(): any {
        const retval: any = {
            input: '',
            button: '',
            iconRatio: '.8',
        };
        if (this.size !== 'medium') {
            retval.input = `uk-form-${this.size}`;
            retval.button = `uk-button-${this.size}`;
        }
        if (this.size === 'small') {
            retval.iconRatio = '.6';
        }
        if (this.size === 'large') {
            retval.iconRatio = '1.1';
        }

        return retval;
    }

    async handleClick() {
        await service.ensureCartCookie();
        window.open(`${service.endpoint}/goto?productid=${this.product}`, '_blank');
        this.fetchCart();
    }

    async fetchCart() {
        await CartModule.GET_CART();
        setTimeout(this.fetchCart, 10000);
    }
}
